import clsx from "clsx";
import type { ReactNode } from "react";
import { Paragraph } from "./typography";
import type { TitleProps } from "./typography";

export interface Props {
	children: ReactNode | string;
	bgColor?: string;
	textColor?: TitleProps["color"];
	className?: string;
}

export function Tag({
	children,
	bgColor = "bg-tag",
	textColor = "primary",
	className,
}: Props) {
	return children !== null ? (
		<span className={clsx("rounded px-3 py-2", bgColor, className)}>
			<Paragraph
				className="inline"
				size="body-small"
				fontWeight="font-medium"
				color={textColor}
			>
				{children}
			</Paragraph>
		</span>
	) : null;
}
